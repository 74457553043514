import Overlay from '@financial-times/o-overlay';
import {
	renderProFeaturesConfig,
	renderProFeaturesConfigButtons
} from './renderer';

const PRO_FEATURES_CONFIG_MODAL_ID = 'pro-features-config-modal';

/**
 * Initialize the widgets configuration
 *
 * @param enabledFeatures {('marketData' | 'articleContext')[]}
 */
export function initProFeaturesConfig(enabledFeatures) {
	if (enabledFeatures.length === 0) return;

	// Add any widgets you want to configure here
	const featuresMap = {
		marketData: {
			// Feature codes are currently only used when reporting enabled/disabled changes via tracking events
			featureCode: 'market-data',
			saveKey: 'marketDataFeatureDisabled',
			title: 'Related market data',
			description:
				'Share price data over the last 30 days for up to 3 organisations related to this article.'
		},
		articleContext: {
			featureCode: 'article-context',
			saveKey: 'articleContextFeatureDisabled',
			title: 'Article Insights',
			description:
				'Get insights on related market data, major events, expert commentary, opinions and more.'
		}
	};

	if (enabledFeatures.length === 0) return;

	const overlay = new Overlay(PRO_FEATURES_CONFIG_MODAL_ID, {
		html: 'placeholder',
		class: PRO_FEATURES_CONFIG_MODAL_ID,
		preventclosing: true,
		customclose: true,
		modal: true,
		zindex: 20
	});

	const configButtons = renderProFeaturesConfigButtons(
		'[data-component="share-nav"] [data-toolbar="share"]'
	);

	configButtons.forEach((button) => {
		if (!button) return;

		button.addEventListener('click', () => {
			overlay.open();
			renderProFeaturesConfig({
				templateClassName: PRO_FEATURES_CONFIG_MODAL_ID,
				onClose: () => overlay.close(),
				features: enabledFeatures.map((key) => featuresMap[key])
			});
		});
	});
}
